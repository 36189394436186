
import { defineComponent } from 'vue'
import { User } from '@/types/user'
import HeaderBar from '@/components/shared/Header.vue'

export default defineComponent({
  name: 'Profile',
  components: {HeaderBar},
  data () {
    return {
      operator: JSON.parse(localStorage.getItem('insylo_operator') || '{}') as User,
    }
  }
})
